import React, { useEffect, useRef, useState } from "react";
import ZoomOutMapIcon from "@mui/icons-material/ZoomOutMap";
import { LookerEmbedSDK } from "@looker/embed-sdk";

export default function QuestionCard({ item, index, isLoaded,onZoom }) {
  const embedContainer = useRef(null);
  
  useEffect(() => {
    let embedInstance;

    if (embedContainer.current) {
      // Clear the container before appending
      embedContainer.current.innerHTML = "";

      // Create and connect the Looker embed
      embedInstance = LookerEmbedSDK.createLookWithUrl(item.url)
        .appendTo(embedContainer.current)
        .on("load", () => {
          console.log(`Look ${item.questionId} loaded`);
        })
        .on("error", (err) => {
          console.error("Error loading Look:", err);
        })
        .build();
      
      embedInstance.connect();
    }

    return () => {
      // Cleanup the DOM manually by clearing the container
      if (embedContainer.current) {
        embedContainer.current.innerHTML = ""; // Clear any appended DOM nodes
      }
      embedInstance = null; // Remove reference to embedInstance
    };
  }, [item]);

  return (
    <div className="question-card">
      {/* <h3>{item.questionId}</h3> */}
      <p>
        {item.staffText && (
          <>
            <strong>Staff:</strong> {item.staffText}
            <br />
          </>
        )}
        {item.parentText && (
          <>
            <strong>Parents:</strong> {item.parentText}
            <br />
          </>
        )}
        {item.pupilText && (
          <>
            <strong>Students:</strong> {item.pupilText}
            <br />
          </>
        )}
      </p>
      <div ref={embedContainer} className="embed-container" ></div>
      <button onClick={onZoom} className="btn-zoom">
        <ZoomOutMapIcon sx={{ color: "#fff", fontSize: "20px" }} />
      </button>
    </div>
  );
}

// export default function QuestionCard({ item , index,isLoaded }) {
//   const [isZoomed, setIsZoomed] = useState(false);

//   const toggleZoom = () => setIsZoomed(!isZoomed);
 
 
//   return (       
//     <div className="question-card">
//       <h3>{item.questionId}</h3>
//       <p>
//         {item.staffText && <><strong>Staff:</strong> {item.staffText}<br /></>}
//         {item.parentText && <><strong>Parents:</strong> {item.parentText}<br /></>}
//         {item.pupilText && <><strong>Students:</strong> {item.pupilText}<br /></>}
//       </p>
//       <div>
//         <iframe
//           frameBorder="0"
//           title={`iframe-${index}`}
//           src={item.url}
//           loading="lazy"
//           sandbox="allow-scripts allow-same-origin allow-forms"
//           allow="fullscreen"
//           isLoaded={isLoaded}    
//           />    
//       </div>
//       <button onClick={toggleZoom} className="btn-zoom">
//         <ZoomOutMapIcon sx={{color: "#fff", fontSize: "20px"}}/>
//       </button>
//     </div>
//   );
// }
