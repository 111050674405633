import React, { useEffect } from 'react';
import { signinRedirectCallback } from '../../services/api/user/userApi';
import { useNavigate } from "react-router-dom";
import { CircularProgress } from '@mui/material';
import welbeelogo from '../../components/assets/images/welbee_logo.svg'

function SigninOidc(props) {
  let navigate = useNavigate();
  useEffect(() => {
    async function signinAsync() {
      debugger;
      await signinRedirectCallback();
      navigate("/login");
    }
    signinAsync();
  })

  return (
    <div className="loading">
      <div className="login-logo">
        <img src={welbeelogo} alt="" />
      </div>
      <div>
        <svg width={0} height={0}>
          <defs>
            <linearGradient id="my_gradient" x1="0%" y1="0%" x2="0%" y2="100%">
              <stop offset="0%" stopColor="#e01cd5" />
              <stop offset="100%" stopColor="#1CB5E0" />
            </linearGradient>
          </defs>
        </svg>
        <CircularProgress sx={{ 'svg circle': { stroke: 'url(#my_gradient)' } }} size={50} />
      </div>        
    </div>
  )
}

export default SigninOidc;