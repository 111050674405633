import request from '../../request'
import {eduIntell} from '../../endpoints'
import { handleApiError } from '../../../components/common/handleApiError';
export default class eduIntellAPI {
  static async GetEDUIntell(
    surveyId,
    customerId,
    customerType,
    fetchedDataset,
    userId,
    userQuery,
    requestType
  ) {
    try {
      return await request({
        url: eduIntell.GetEDUIntell(
          surveyId,
          customerId,
          customerType,
          fetchedDataset,
          userId,
          userQuery,
          requestType
        ),
        method: 'GET',
      });
    } catch (error) {
      handleApiError(error, 'EDU AI API');
      throw error;
    }
  }
}
