import request from '../../request'
import {survey} from '../../endpoints'
import { handleApiError } from '../../../components/common/handleApiError'

export default class surveysApi {
  static async post(data) {
    return request({
      url: survey.surveys(),
      method: 'POST',
      data: data,
    })
  }

  static async put(data) {
    return request({
      url: survey.surveys(),
      method: 'PUT',
      data: data,
    })
  }

  static async get(token) {
    return request({
      url: survey.get(),
      method: 'POST',
      data: token,
    })
  }
  static async getBySurveyId(surveyId) {
    return request({
      url: survey.getBySurveyId(),
      method: 'POST',
      data: surveyId,
    })
  }
  static async GetSurveyDashboard(surveyId, cType) {
    try {
      return await request({
        url: survey.getSurveyDashboard(surveyId, cType),
        method: 'GET',
      })
    } catch (error) {
      handleApiError(error, 'Failed to get surveys data.');
      throw error;
    }
   
  }
  static async getAll(cId, cType, limit) {
    try {
      return await request({
        url: survey.getAll(cId, cType, limit),
        method: 'GET',
      })
    } catch (error) {
      handleApiError(error, 'Failed to get surveys data.');
      throw error;
    }
    
  }

  static async submit(obj) {
    return request({
      url: survey.submit(),
      method: 'POST',
      data: JSON.stringify(obj),
    })
  }

  static async updateParticipants(token, filters) {
    return request({
      url: survey.updateParticipants(),
      method: 'POST',
      data: JSON.stringify({
        token: token,
        participantFilter: JSON.stringify(filters),
      }),
    })
  }

  static async getSurveyForResults(schoolId, matId) {
    return request({
      url: survey.getSurveyForResults(schoolId, matId),
      method: 'GET',
    })
  }
}
