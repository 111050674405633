import {configureStore} from '@reduxjs/toolkit';
import userReducer from './reducers/userSlice';
import customerReducer from './reducers/customerSlice';
import authReducer from './reducers/authSlice';

export const store = configureStore({
  reducer: {
    user: userReducer,
    customer: customerReducer,
    auth: authReducer
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: false
  })
})