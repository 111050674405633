

import React, { useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExpandArrowsAlt } from '@fortawesome/free-solid-svg-icons';
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend,ChartDataLabels);
export default function SurveyCharts6() {
  

    const surveyData6 = [
        {
          questionHeader: 'Q1',
          questionText: (
            <p style={{ textAlign: 'center' }}>
              <strong>Parents:</strong> On a scale of 1-5, how much would you say your child enjoys being at this school?
            </p>
          ),
          labels: ['1', '2', '3', '4', '5'],
          datasets: [
            {
              label: 'Parent',
              data: [6, 10, 20, 34, 30],
              backgroundColor: '#ffcc33',
            }
          ],
        },
        {
          questionHeader: 'Q2',
          questionText: (
            <p style={{ textAlign: 'center' }}>
              <strong>Parents:</strong> Does your child have someone to talk to at school if they are upset about something?
            </p>
          ),
          labels: ['Yes', 'No'],
          datasets: [
            {
              label: 'Parent',
              data: [81, 19],
              backgroundColor: '#ffcc33',
            }
          ],
        },
        {
          questionHeader: 'Q3',
          questionText: (
            <p style={{ textAlign: 'center' }}>
              <strong>Parents:</strong> Do you feel that you have the opportunity to express your view of your child’s feelings towards school and their own wellbeing?
            </p>
          ),
          labels: ['Yes', 'No'],
          datasets: [
            {
              label: 'Parent',
              data: [66, 34],
              backgroundColor: '#ffcc33',
            }
          ],
        },
        {
          questionHeader: 'Q4',
          questionText: (
            <p style={{ textAlign: 'center' }}>
              <strong>Parents:</strong> On a scale of 1 to 5, how happy are you with what our school does to support your child’s wellbeing?
            </p>
          ),
          labels: ['1', '2', '3', '4', '5'],
          datasets: [
            {
              label: 'Parent',
              data: [4, 8, 19, 40, 29],
              backgroundColor: '#ffcc33',
            }
          ],
        },
        {
          questionHeader: 'Q5',
          questionText: (
            <p style={{ textAlign: 'center' }}>
              <strong>Parents:</strong> Do you know what mental health and wellbeing support is available to your child?
            </p>
          ),
          labels: ['Yes', 'No'],
          datasets: [
            {
              label: 'Parent',
              data: [55, 45],
              backgroundColor: '#ffcc33',
            }
          ],
        },
        {
          questionHeader: 'Q6',
          questionText: (
            <p style={{ textAlign: 'center' }}>
              <strong>Parents:</strong> On a scale of 1 to 5, how much would you say the school prioritises each of the following: Academic outcomes/grades, Physical activity, Wellbeing and happiness
            </p>
          ),
          labels: ['1', '3', '5'],
          datasets: [
            {
              label: 'Academic outcomes/grades',
              data: [5, 17, 78],
              backgroundColor: '#ffcc33',
            },
            {
              label: 'Physical activity',
              data: [13, 25, 62],
              backgroundColor: '#ffcc33',
            },
            {
              label: 'Wellbeing and happiness',
              data: [11, 21, 68],
              backgroundColor: '#ffcc33',
            }
          ],
        },
        {
          questionHeader: 'Q7',
          questionText: (
            <p style={{ textAlign: 'center' }}>
              <strong>Parents:</strong> How much do you agree or disagree with the following statements? The school provides a safe environment for my child. The school is an engaging and fun environment for my child to learn. Staff at school have good relationships with parents/carers of the children.
            </p>
          ),
          labels: ['Strongly Agree', 'Agree', 'Neutral', 'Disagree', 'Strongly Disagree'],
          datasets: [
            {
              label: 'The school provides a safe environment for my child',
              data: [59, 31, 5, 3, 2],
              backgroundColor: '#ffcc33',
            },
            {
              label: 'The school is an engaging and fun environment for my child to learn',
              data: [54, 36, 5, 3, 2],
              backgroundColor: '#ffcc33',
            },
            {
              label: 'Staff at school have good relationships with parents/carers of the children',
              data: [56, 34, 5, 3, 2],
              backgroundColor: '#ffcc33',
            }
          ],
        },
        {
          questionHeader: 'Q8',
          questionText: (
            <p style={{ textAlign: 'center' }}>
              <strong>Parents:</strong> On a scale of 1 to 5, how happy are YOU with the school on the whole?
            </p>
          ),
          labels: ['1', '2', '3', '4', '5'],
          datasets: [
            {
              label: 'Parent',
              data: [5, 8, 16, 40, 31],
              backgroundColor: '#ffcc33',
            }
          ],
        },
        {
            questionHeader: 'Q9',
            questionText: (
              <p style={{ textAlign: 'center' }}>
                <strong>Parents:</strong> Is there anything you think the school could do to improve how it supports you or your child that it is not currently doing?
              </p>
            ),
            labels: ['Common Themes'],
            datasets: [
              {
                label: 'Parent',
                data: [25, 15, 10, 20], // Example data points for demonstration
                backgroundColor: '#ffcc33',
              }
            ],
            responseSummary: {
              commonThemes: [
                'Expanded after-school programs',
                'Enhanced communication methods',
                'More mental health resources',
                'Improved classroom facilities'
              ],
              additionalComments: 'Several parents requested more frequent updates on their child’s progress and additional workshops on online safety.'
            }
          },
        {
          questionHeader: 'Q10',
          questionText: (
            <p style={{ textAlign: 'center' }}>
              <strong>Parents:</strong> How well do you feel the school communicates with parents?
            </p>
          ),
          labels: ['Excellent', 'Good', 'Okay', 'Poor', 'Very Poor'],
          datasets: [
            {
              label: 'Parent',
              data: [47, 38, 10, 3, 2],
              backgroundColor: '#ffcc33',
            }
          ],
        },
        {
          questionHeader: 'Q11',
          questionText: (
            <p style={{ textAlign: 'center' }}>
              <strong>Parents:</strong> Do you feel that your child is making good progress at school?
            </p>
          ),
          labels: ['Yes', 'No', 'Maybe'],
          datasets: [
            {
              label: 'Parent',
              data: [74, 13, 13],
              backgroundColor: '#ffcc33',
            }
          ],
        }
      ];
      
      
  
  const [isZoomed, setIsZoomed] = useState(false);

  
  const options = {
    responsive: true,
    maintainAspectRatio: !isZoomed,
    aspectRatio: 1.2, 
    plugins: {
      legend: {
        position: 'bottom',
        labels: {
          usePointStyle: true,
        },
      },
      datalabels: {
        anchor: 'end',
        align: 'top',
        formatter: (value) => `${value}%`,
        color: 'black',
        font: {
          weight: 'bold',
        },
      },
      tooltip: {
        callbacks: {
          label: (tooltipItem) => `${tooltipItem.raw}%`,
        },
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        max: 100,
        ticks: {
          callback: (value) => `${value}%`,
        },
      },
      x: {
        ticks: {
          maxRotation: 45,
          minRotation: 0,
        },
      },
    },
  };

  const toggleZoom = () => {
    setIsZoomed(!isZoomed);
  };

  return (
    <div style={{ display: 'flex', flexWrap: 'wrap', gap: '10px', justifyContent: 'space-between' }}>
      {surveyData6.map((item, index) => (
        <div
          key={index}
          style={{
            width: isZoomed ? '100%' : '49.5%',
            backgroundColor: '#fff',
            borderRadius: '0.0rem',
            boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
            padding: '10px',
            marginBottom: '10px',
            position: 'relative',
            transition: 'width 0.3s', // Smooth transition for width change
          }}
        >
          <h3 style={{ textAlign: 'center' }}>{item.questionHeader}</h3>
          {item.questionText}
          <div style={{ paddingTop: '14px' }}>
            <Bar
              data={{
                labels: item.labels,
                datasets: item.datasets,
              }}
              key={index}
              options={options}
            />
          </div>
          <button
            onClick={toggleZoom}
            style={{
              position: 'absolute',
              top: '10px',
              right: '10px',
              width: '30px',
              height: '30px',
              borderRadius: '50%',
              border: 'none',
              cursor: 'pointer',
              backgroundColor: '#007A7A',
              color: '#fff',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              boxShadow: '0 2px 5px rgba(0, 0, 0, 0.15)',
              fontSize: '18px',
            }}
          >
            <FontAwesomeIcon icon={faExpandArrowsAlt} color={'#fff'} />
          </button>
        </div>
      ))}
    </div>
  );
}