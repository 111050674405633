export const detectIncognito = async () => {
    return new Promise((resolve, reject) => {
      let browserName = "Unknown";
  
      const callback = (isPrivate) => {
        resolve({ isPrivate, browserName });
      };
  
      const identifyChromium = () => {
        const ua = navigator.userAgent;
        if (ua.includes("Chrome")) {
          if (navigator.brave) return "Brave";
          if (ua.includes("Edg")) return "Edge";
          if (ua.includes("OPR")) return "Opera";
          return "Chrome";
        }
        return "Chromium";
      };
  
      const assertEvalToString = (value) => value === eval.toString().length;
  
      const feid = () => {
        try {
          (-1).toFixed(-1);
        } catch (e) {
          return e.message.length; // Safari 44, Chrome 51, Firefox 25
        }
        return 0;
      };
  
      const isSafari = () => feid() === 44;
      const isChrome = () => feid() === 51;
      const isFirefox = () => feid() === 25;
      const isMSIE = () =>
        navigator.msSaveBlob !== undefined && assertEvalToString(39);
  
      // Safari detection
      const safariPrivateTest = () => {
        const newSafariTest = () => {
          const tmpName = String(Math.random());
          try {
            const db = indexedDB.open(tmpName, 1);
            db.onupgradeneeded = (e) => {
              const res = e.target?.result;
              try {
                res
                  .createObjectStore("test", { autoIncrement: true })
                  .put(new Blob());
                callback(false);
              } catch (err) {
                const errorMessage =
                  typeof err.message === "string" ? err.message : "";
                const matchesExpectedError = errorMessage.includes(
                  "BlobURLs are not yet supported"
                );
                callback(matchesExpectedError);
              } finally {
                res?.close();
                indexedDB.deleteDatabase(tmpName);
              }
            };
          } catch {
            callback(false);
          }
        };
  
        const oldSafariTest = () => {
          try {
            window.openDatabase(null, null, null, null);
          } catch {
            callback(true);
            return;
          }
          try {
            localStorage.setItem("test", "1");
            localStorage.removeItem("test");
          } catch {
            callback(true);
            return;
          }
          callback(false);
        };
  
        if (navigator.maxTouchPoints !== undefined) newSafariTest();
        else oldSafariTest();
      };
  
      // Chrome detection
      const chromePrivateTest = () => {
        const storageQuotaChromePrivateTest = () => {
          navigator.webkitTemporaryStorage.queryUsageAndQuota(
            (_, quota) => {
              const quotaInMib = Math.round(quota / (1024 * 1024));
              const quotaLimitInMib =
                Math.round(performance.memory.jsHeapSizeLimit / (1024 * 1024)) *
                2;
              callback(quotaInMib < quotaLimitInMib);
            },
            (err) => {
              reject(new Error(`Failed to query storage quota: ${err.message}`));
            }
          );
        };
  
        const oldChromePrivateTest = () => {
          const fs = window.webkitRequestFileSystem;
          fs(
            0,
            1,
            () => callback(false),
            () => callback(true)
          );
        };
  
        if (Promise.allSettled) storageQuotaChromePrivateTest();
        else oldChromePrivateTest();
      };
  
      // Firefox detection
      const firefoxPrivateTest = () => {
        callback(navigator.serviceWorker === undefined);
      };
  
      // IE detection
      const msiePrivateTest = () => {
        callback(indexedDB === undefined);
      };
  
      // Main detection logic
      const main = () => {
        if (isSafari()) {
          browserName = "Safari";
          safariPrivateTest();
        } else if (isChrome()) {
          browserName = identifyChromium();
          chromePrivateTest();
        } else if (isFirefox()) {
          browserName = "Firefox";
          firefoxPrivateTest();
        } else if (isMSIE()) {
          browserName = "Internet Explorer";
          msiePrivateTest();
        } else {
          reject(new Error("Cannot determine the browser."));
        }
      };
  
      main();
    });
  };
  
  export default detectIncognito;
  