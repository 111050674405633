

import React, { useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExpandArrowsAlt } from '@fortawesome/free-solid-svg-icons';
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend,ChartDataLabels);
export default function SurveyCharts4() {
  

const surveyData4 = [
    {
      questionHeader: 'Q1',
      questionText: (
        <p style={{ textAlign: 'center' }}>
          During the past week... I felt ill.
        </p>
      ),
      labels: ['Never', 'Seldom', 'Sometimes', 'Often', 'All the time'],
      datasets: [
        {
          label: 'General Analysis',
          data: [67, 16, 12, 4, 1],
          backgroundColor: '#45338C',
        },
      ],
    },
    {
      questionHeader: 'Q2',
      questionText: (
        <p style={{ textAlign: 'center' }}>
          During the past week... I was tired and worn-out.
        </p>
      ),
      labels: ['Never', 'Seldom', 'Sometimes', 'Often', 'All the time'],
      datasets: [
        {
          label: 'General Analysis',
          data: [43, 18, 14, 24, 1],
          backgroundColor: '#45338C',
        },
      ],
    },
    {
      questionHeader: 'Q3',
      questionText: (
        <p style={{ textAlign: 'center' }}>
          During the past week... I was proud of myself.
        </p>
      ),
      labels: ['Never', 'Seldom', 'Sometimes', 'Often', 'All the time'],
      datasets: [
        {
          label: 'General Analysis',
          data: [14, 6, 10, 46, 24],
          backgroundColor: '#45338C',
        },
      ],
    },
    {
      questionHeader: 'Q4',
      questionText: (
        <p style={{ textAlign: 'center' }}>
          During the past week... I felt pleased with myself.
        </p>
      ),
      labels: ['Never', 'Seldom', 'Sometimes', 'Often', 'All the time'],
      datasets: [
        {
          label: 'General Analysis',
          data: [2, 28, 20, 28, 22],
          backgroundColor: '#45338C',
        },
      ],
    },
    {
      questionHeader: 'Q5',
      questionText: (
        <p style={{ textAlign: 'center' }}>
          During the past week... I had fun and laughed a lot.
        </p>
      ),
      labels: ['Never', 'Seldom', 'Sometimes', 'Often', 'All the time'],
      datasets: [
        {
          label: 'General Analysis',
          data: [1, 14, 15, 45, 25],
          backgroundColor: '#45338C',
        },
      ],
    },
    {
      questionHeader: 'Q6',
      questionText: (
        <p style={{ textAlign: 'center' }}>
          During the past week... I was bored.
        </p>
      ),
      labels: ['Never', 'Seldom', 'Sometimes', 'Often', 'All the time'],
      datasets: [
        {
          label: 'General Analysis',
          data: [13, 18, 35, 20, 14],
          backgroundColor: '#45338C',
        },
      ],
    },
    {
      questionHeader: 'Q7',
      questionText: (
        <p style={{ textAlign: 'center' }}>
          During the past week... I felt alone.
        </p>
      ),
      labels: ['Never', 'Seldom', 'Sometimes', 'Often', 'All the time'],
      datasets: [
        {
          label: 'General Analysis',
          data: [37, 20, 15, 25, 3],
          backgroundColor: '#45338C',
        },
      ],
    },
    {
      questionHeader: 'Q8',
      questionText: (
        <p style={{ textAlign: 'center' }}>
          During the past week... I was scared.
        </p>
      ),
      labels: ['Never', 'Seldom', 'Sometimes', 'Often', 'All the time'],
      datasets: [
        {
          label: 'General Analysis',
          data: [62, 8, 12, 13, 5],
          backgroundColor: '#45338C',
        },
      ],
    },
    {
      questionHeader: 'Q9',
      questionText: (
        <p style={{ textAlign: 'center' }}>
          During the past week... I played with friends.
        </p>
      ),
      labels: ['Never', 'Seldom', 'Sometimes', 'Often', 'All the time'],
      datasets: [
        {
          label: 'General Analysis',
          data: [1, 7, 20, 52, 20],
          backgroundColor: '#45338C',
        },
      ],
    },
    {
      questionHeader: 'Q10',
      questionText: (
        <p style={{ textAlign: 'center' }}>
          During the past week... I got along well with my friends.
        </p>
      ),
      labels: ['Never', 'Seldom', 'Sometimes', 'Often', 'All the time'],
      datasets: [
        {
          label: 'General Analysis',
          data: [11, 6, 10, 52, 21],
          backgroundColor: '#45338C',
        },
      ],
    },
    {
      questionHeader: 'Q11',
      questionText: (
        <p style={{ textAlign: 'center' }}>
          During the past week... doing my schoolwork was easy.
        </p>
      ),
      labels: ['Never', 'Seldom', 'Sometimes', 'Often', 'All the time'],
      datasets: [
        {
          label: 'General Analysis',
          data: [15, 12, 10, 42, 21],
          backgroundColor: '#45338C',
        },
      ],
    },
    {
      questionHeader: 'Q12',
      questionText: (
        <p style={{ textAlign: 'center' }}>
          During the past week... I enjoyed my lessons.
        </p>
      ),
      labels: ['Never', 'Seldom', 'Sometimes', 'Often', 'All the time'],
      datasets: [
        {
          label: 'General Analysis',
          data: [3, 7, 20, 45, 25],
          backgroundColor: '#45338C',
        },
      ],
    },
    {
      questionHeader: 'Q13',
      questionText: (
        <p style={{ textAlign: 'center' }}>
          During the past week... I worried about my future.
        </p>
      ),
      labels: ['Never', 'Seldom', 'Sometimes', 'Often', 'All the time'],
      datasets: [
        {
          label: 'General Analysis',
          data: [14, 16, 28, 28, 14],
          backgroundColor: '#45338C',
        },
      ],
    },
  ];
  
  const [isZoomed, setIsZoomed] = useState(false);

  
  const options = {
    responsive: true,
    maintainAspectRatio: !isZoomed,
    aspectRatio: 1.2, 
    plugins: {
      legend: {
        position: 'bottom',
        labels: {
          usePointStyle: true,
        },
      },
      datalabels: {
        anchor: 'end',
        align: 'top',
        formatter: (value) => `${value}%`,
        color: 'black',
        font: {
          weight: 'bold',
        },
      },
      tooltip: {
        callbacks: {
          label: (tooltipItem) => `${tooltipItem.raw}%`,
        },
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        max: 100,
        ticks: {
          callback: (value) => `${value}%`,
        },
      },
      x: {
        ticks: {
          maxRotation: 45,
          minRotation: 0,
        },
      },
    },
  };

  const toggleZoom = () => {
    setIsZoomed(!isZoomed);
  };

  return (
    <div style={{ display: 'flex', flexWrap: 'wrap', gap: '10px', justifyContent: 'space-between' }}>
      {surveyData4.map((item, index) => (
        <div
          key={index}
          style={{
            width: isZoomed ? '100%' : '49.5%',
            backgroundColor: '#fff',
            borderRadius: '0.0rem',
            boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
            padding: '10px',
            marginBottom: '10px',
            position: 'relative',
            transition: 'width 0.3s', // Smooth transition for width change
          }}
        >
          <h3 style={{ textAlign: 'center' }}>{item.questionHeader}</h3>
          {item.questionText}
          <div style={{ paddingTop: '14px' }}>
            <Bar
              data={{
                labels: item.labels,
                datasets: item.datasets,
              }}
              key={index}
              options={options}
            />
          </div>
          <button
            onClick={toggleZoom}
            style={{
              position: 'absolute',
              top: '10px',
              right: '10px',
              width: '30px',
              height: '30px',
              borderRadius: '50%',
              border: 'none',
              cursor: 'pointer',
              backgroundColor: '#007A7A',
              color: '#fff',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              boxShadow: '0 2px 5px rgba(0, 0, 0, 0.15)',
              fontSize: '18px',
            }}
          >
            <FontAwesomeIcon icon={faExpandArrowsAlt} color={'#fff'} />
          </button>
        </div>
      ))}
    </div>
  );
}