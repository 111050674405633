

import React, { useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExpandArrowsAlt } from '@fortawesome/free-solid-svg-icons';
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend,ChartDataLabels);
export default function SurveyCharts3() {
  
  const surveyData3 = [
    {
      questionHeader: 'Q1',
      questionText: (
        <p style={{ textAlign: 'center' }}>
          <strong>Students:</strong> How would you describe the behaviour of your peers during lessons?<br />
          <strong>Parents:</strong> Based on what you have heard from your child(ren), how would you describe the behaviour of pupils at school?<br />
          <strong>Staff:</strong> How would you describe the general behaviour of pupils in your lessons?
        </p>
      ),
      labels: ['Mostly Positive and Respectful', 'Generally Good', 'Mix of good and disruptive behaviour', 'Often disruptive', 'Mostly disrespectful and disruptive'],
      datasets: [
        {
          label: 'Student',
          data: [59, 21, 12, 6, 3],
          backgroundColor: '#4db8ff',
        },
        {
          label: 'Parent',
          data: [61, 19, 11, 6, 2],
          backgroundColor: '#ffcc33',
        },
        {
          label: 'Staff',
          data: [58, 22, 11, 6, 2],
          backgroundColor: '#ff6699',
        },
      ],
    },
    {
      questionHeader: 'Q2',
      questionText: (
        <p style={{ textAlign: 'center' }}>
          <strong>Students:</strong> Do you feel safe and respected by your peers?<br />
          <strong>Parents:</strong> Do you feel that your child is safe and respected at school?<br />
          <strong>Staff:</strong> Do you feel safe and respected by students at school?
        </p>
      ),
      labels: ['Yes', 'No'],
      datasets: [
        {
          label: 'Student',
          data: [88, 12],
          backgroundColor: '#4db8ff',
        },
        {
          label: 'Parent',
          data: [80, 20],
          backgroundColor: '#ffcc33',
        },
        {
          label: 'Staff',
          data: [81, 19],
          backgroundColor: '#ff6699',
        },
      ],
    },

  
    {
      questionHeader: 'Q3',
      questionText: (
        <p style={{ textAlign: 'center' }}>
          <strong>Students:</strong> The rules at school and how I am expected to behave are clear.<br />
          <strong>Parents:</strong> The school rules and expectations for my child(ren)'s behaviour are clear.<br />
          <strong>Staff:</strong> The school rules and expectations for students' behaviour are clear and communicated effectively.
        </p>
      ),
      labels: ['Strongly Disagree', 'Disagree', 'Neutral', 'Agree', 'Strongly Agree'],
      datasets: [
        {
          label: 'Student',
          data: [2, 6, 15, 34, 43],
          backgroundColor: '#4db8ff',
        },
        {
          label: 'Parent',
          data: [1, 5, 12, 28, 42],
          backgroundColor: '#ffcc33',
        },
        {
          label: 'Staff',
          data: [1, 3, 13, 41, 41],
          backgroundColor: '#ff6699',
        },
      ],
    },
    {
      questionHeader: 'Q4',
      questionText: (
        <p style={{ textAlign: 'center' }}>
          <strong>Students:</strong> How often do you witness bullying or unkind behaviour at school?<br />
          <strong>Parents:</strong> How often do you think your child witnesses bullying or unkind behaviour at school?<br />
          <strong>Staff:</strong> How often do you witness bullying or unkind behaviour between students at school?
        </p>
      ),
      labels: ['Never', 'Rarely', 'Sometimes', 'Often', 'Very Often'],
      datasets: [
        {
          label: 'Student',
          data: [51, 28, 11, 4, 3],
          backgroundColor: '#4db8ff',
        },
        {
          label: 'Parent',
          data: [41, 28, 13, 4, 3],
          backgroundColor: '#ffcc33',
        },
        {
          label: 'Staff',
          data: [54, 25, 15, 4, 2],
          backgroundColor: '#ff6699',
        },
      ],
    },
    {
      questionHeader: 'Q5',
      questionText: (
        <p style={{ textAlign: 'center' }}>
          <strong>Students:</strong> Do you think the school does a good job of addressing any bullying or poor behaviour if it happens?<br />
          <strong>Parents:</strong> Do you think the school does a good job of addressing bullying and poor behaviour?<br />
          <strong>Staff:</strong> Do you think the school does a good job of addressing bullying and poor behaviour?
        </p>
      ),
      labels: ['Strongly Agree', 'Agree', 'Neutral', 'Disagree', 'Strongly Disagree'],
      datasets: [
        {
          label: 'Student',
          data: [48, 25, 12, 10, 5],
          backgroundColor: '#4db8ff',
        },
        {
          label: 'Parent',
          data: [40, 23, 13, 9, 3],
          backgroundColor: '#ffcc33',
        },
        {
          label: 'Staff',
          data: [51, 23, 11, 12, 1],
          backgroundColor: '#ff6699',
        },
      ],
    },
    {
      questionHeader: 'Q6',
      questionText: (
        <p style={{ textAlign: 'center' }}>
          <strong>Students:</strong> Do you feel like you have a trusted adult at school you can talk to if you have a problem?<br />
          <strong>Parents:</strong> Do you feel your child has a trusted adult at school they can talk to if they have a problem?<br />
          <strong>Staff:</strong> Do you think all students feel like they have a trusted adult at school they can talk to if they have a problem?
        </p>
      ),
      labels: ['Yes', 'No'],
      datasets: [
        {
          label: 'Student',
          data: [88, 12],
          backgroundColor: '#4db8ff',
        },
        {
          label: 'Parent',
          data: [85, 15],
          backgroundColor: '#ffcc33',
        },
        {
          label: 'Staff',
          data: [80, 20],
          backgroundColor: '#ff6699',
        },
      ],
    },
    {
      questionHeader: 'Q7',
      questionText: (
        <p style={{ textAlign: 'center' }}>
          <strong>Students:</strong> Do you think teachers at school encourage you to make good choices about your behaviour?<br />
          <strong>Parents:</strong> Do you think the school encourages students to make good choices about their behaviour?<br />
          <strong>Staff:</strong> Do you think the school provides effective strategies and support to encourage students to make good choices about their behaviour?
        </p>
      ),
      labels: ['Strongly Agree', 'Agree', 'Neutral', 'Disagree', 'Strongly Disagree'],
      datasets: [
        {
          label: 'Student',
          data: [52, 12, 10, 4, 2],
          backgroundColor: '#4db8ff',
        },
        {
          label: 'Parent',
          data: [49, 10, 10, 4, 2],
          backgroundColor: '#ffcc33',
        },
        {
          label: 'Staff',
          data: [51, 10, 10, 3, 1],
          backgroundColor: '#ff6699',
        },
      ],
    },
    {
      questionHeader: 'Q8',
      questionText: (
        <p style={{ textAlign: 'center' }}>
          <strong>Students:</strong> Have you seen a knife or weapon at school?<br />
          <strong>Parents:</strong> Have you heard from your child(ren) that they have seen a knife or weapon at school?<br />
          <strong>Staff:</strong> Have you witnessed a knife or weapon at school?
        </p>
      ),
      labels: ['Yes', 'No'],
      datasets: [
        {
          label: 'Student',
          data: [0.6, 99.4],
          backgroundColor: '#4db8ff',
        },
        {
          label: 'Parent',
          data: [0.4, 99.6],
          backgroundColor: '#ffcc33',
        },
        {
          label: 'Staff',
          data: [0.3, 99.7],
          backgroundColor: '#ff6699',
        },
      ],
    },
  ];

  const [isZoomed, setIsZoomed] = useState(false);

  
  const options = {
    responsive: true,
    maintainAspectRatio: !isZoomed,
    aspectRatio: 1.2, 
    plugins: {
      legend: {
        position: 'bottom',
        labels: {
          usePointStyle: true,
        },
      },
      datalabels: {
        anchor: 'end',
        align: 'top',
        formatter: (value) => `${value}%`,
        color: 'black',
        font: {
          weight: 'bold',
        },
      },
      tooltip: {
        callbacks: {
          label: (tooltipItem) => `${tooltipItem.raw}%`,
        },
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        max: 100,
        ticks: {
          callback: (value) => `${value}%`,
        },
      },
      x: {
        ticks: {
          maxRotation: 45,
          minRotation: 0,
        },
      },
    },
  };

  const toggleZoom = () => {
    setIsZoomed(!isZoomed);
  };

  return (
    <div style={{ display: 'flex', flexWrap: 'wrap', gap: '10px', justifyContent: 'space-between' }}>
      {surveyData3.map((item, index) => (
        <div
          key={index}
          style={{
            width: isZoomed ? '100%' : '49.5%',
            backgroundColor: '#fff',
            borderRadius: '0.0rem',
            boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
            padding: '10px',
            marginBottom: '10px',
            position: 'relative',
            transition: 'width 0.3s', // Smooth transition for width change
          }}
        >
          <h3 style={{ textAlign: 'center' }}>{item.questionHeader}</h3>
          {item.questionText}
          <div style={{ paddingTop: '14px' }}>
            <Bar
              data={{
                labels: item.labels,
                datasets: item.datasets,
              }}
              key={index}
              options={options}
            />
          </div>
          <button
            onClick={toggleZoom}
            style={{
              position: 'absolute',
              top: '10px',
              right: '10px',
              width: '30px',
              height: '30px',
              borderRadius: '50%',
              border: 'none',
              cursor: 'pointer',
              backgroundColor: '#007A7A',
              color: '#fff',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              boxShadow: '0 2px 5px rgba(0, 0, 0, 0.15)',
              fontSize: '18px',
            }}
          >
            <FontAwesomeIcon icon={faExpandArrowsAlt} color={'#fff'} />
          </button>
        </div>
      ))}
    </div>
  );
}