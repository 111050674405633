import React, { useState } from 'react';
import { Container, Grid, Stack, Chip, MenuItem } from '@mui/material';
import Header from '../../components/layouts/header/Header';
import WbTextField from '../../components/common/WbTextField';

import { useQuery } from '@tanstack/react-query';
import surveysApi from '../../services/api/surveys/surveysApi';
import WbFloatButton from '../../components/common/WbFloatButton';
import SurveyCharts5 from '../DemoChartDashboard5';
import { surveyOptions } from '../EDUDashboard3/EDUDashboard3';
import { useNavigate } from 'react-router-dom';
import HeatmapTable from '../Heatmap4';

const scoreData = [
  {
    question: 'Q1',
    studentScore: null,  // No average score in original JSON
    parentScore: null,   // No average score in original JSON
    generalScore: null,  // No average score in original JSON
  },
  {
    question: 'Q2',
    studentScore: 2.9,
    parentScore: 2.7,
    generalScore: 2.8,
  },
  {
    question: 'Q3',
    studentScore: null,  // No average score in original JSON
    parentScore: null,   // No average score in original JSON
    generalScore: null,  // No average score in original JSON
  },
  {
    question: 'Q4',
    studentScore: null,  // No average score in original JSON
    parentScore: null,   // No average score in original JSON
    generalScore: null,  // No average score in original JSON
  },
  {
    question: 'Q5',
    studentScore: null,  // No average score in original JSON
    parentScore: null,   // No average score in original JSON
    generalScore: null,  // No average score in original JSON
  },
  {
    question: 'Q6',
    studentScore: null,  // No average score in original JSON
    parentScore: null,   // No average score in original JSON
    generalScore: null,  // No average score in original JSON
  },
  {
    question: 'Q7',
    studentScore: null,  // No average score in original JSON
    parentScore: null,   // No average score in original JSON
    generalScore: null,  // No average score in original JSON
  },
  {
    question: 'Q8',
    studentScore: null,  // No average score in original JSON
    parentScore: null,   // No average score in original JSON
    generalScore: null,  // No average score in original JSON
  },
  {
    question: 'Q9',
    studentScore: null,  // No average score in original JSON
    parentScore: null,   // No average score in original JSON
    generalScore: null,  // No average score in original JSON
  },
  {
    question: 'Q10',
    studentScore: null,  // No average score in original JSON
    parentScore: null,   // No average score in original JSON
    generalScore: null,  // No average score in original JSON
  },
];

export default function EDUDashboard5() {
 const navigate = useNavigate()
  const customer ={
    id: 2006,
    customerType: 1
  }; //useCustomerData();
  const [selectedSurvey, setSelectedSurvey] = useState(3);

  // Fetch all survey options using react-query
//   const { data: surveyOptions = [], isLoading: isLoadingSurveys } = useQuery(
//     ['surveys', customer.id, customer.customerType],
//     () => surveysApi.getAll(customer.id, customer.customerType, 50),
//     {
//       onSuccess: (res) => {
//         if (res && res.length > 0) {
//           setSelectedSurvey(res[0].id);
//         }
//       },
//     }
//   );

//   // Fetch detailed survey data for the selected survey
//   const { data: survey = {}, isLoading: isLoadingSurveyDetails } = useQuery(
//     ['surveyDetails', selectedSurvey],
//     () => (selectedSurvey ? surveysApi.getBySurveyId(selectedSurvey) : Promise.resolve(null)),
//     {
//       enabled: !!selectedSurvey, // Only run this query if a survey is selected
//     }
//   );

const handleSurveyChange = (e) => {
  const surveyId = e.target.value;
  setSelectedSurvey(surveyId);
  if (surveyId === 55) {
    navigate('/EDUDashboard3');
  } else if (surveyId === 150) {
    navigate('/EDUDashboard4');
  } else if (surveyId === 90) {
    navigate('/EDUDashboard5');
  } else if (surveyId === 110) {
    navigate('/EDUDashboard6');
  }
};

  const getDateString = (date, format) => {
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    const getPaddedComp = (comp) => (parseInt(comp) < 10 ? '0' + comp : comp);
    let formattedDate = format;
    const o = {
      'y+': date.getFullYear(), // year
      'M+': months[date.getMonth()], // month
      'd+': getPaddedComp(date.getDate()), // day
      'h+': getPaddedComp(date.getHours() > 12 ? date.getHours() % 12 : date.getHours()), // hour (12-hour format)
      'H+': getPaddedComp(date.getHours()), // hour (24-hour format)
      'm+': getPaddedComp(date.getMinutes()), // minute
      's+': getPaddedComp(date.getSeconds()), // second
      'S+': getPaddedComp(date.getMilliseconds()), // millisecond
      'b+': date.getHours() >= 12 ? 'PM' : 'AM', // AM/PM
    };

    for (let k in o) {
      if (new RegExp('(' + k + ')').test(format)) {
        formattedDate = formattedDate.replace(RegExp.$1, o[k]);
      }
    }
    return formattedDate;
  };

  const getAudienceChip = (audience) => {
    switch (audience) {
      case 1:
        return <Chip className="audience-chip" label={'Staff'} size="small" />;
      case 2:
        return <Chip className="audience-chip" label={'Parents'} size="small" />;
      case 3:
        return (
          <>
            <Chip className="audience-chip" label={'Staff'} size="small" />
            <Chip className="audience-chip" label={'Parents'} size="small" />
          </>
        );
      case 4:
        return <Chip className="audience-chip" label={'Students'} size="small" />;
      case 5:
        return (
          <>
            <Chip className="audience-chip" label={'Students'} size="small" />
            <Chip className="audience-chip" label={'Staff'} size="small" />
          </>
        );
      case 6:
        return (
          <>
            <Chip className="audience-chip" label={'Students'} size="small" />
            <Chip className="audience-chip" label={'Parents'} size="small" />
          </>
        );
      default:
        return (
          <>
            <Chip className="audience-chip" label={'Staff'} size="small" />
            <Chip className="audience-chip" label={'Students'} size="small" />
            <Chip className="audience-chip" label={'Parents'} size="small" />
          </>
        );
    }
  };

  return (
    <>
      <Header />
      <div className="main-container insights-container">
        <Container maxWidth="lg">
          <Grid container mt={3}>
            <Grid item lg={7} md={7} sm={6} xs={12}>
            <h1>Latest Survey</h1>
            <Stack direction="column" mt={4}>
    <h2>{'Riverdale Academy Trust E-Safety Survey'}</h2>
    <Stack direction="row" alignItems="center" my={2}>
      Audience:
      <Stack direction="row" spacing={0.5} ml={0.5}>
        {/* Replace with dynamic audiences if needed */}
        {['Students', 'Parents'].map((audience, index) => (
          <Chip key={index} className="audience-chip" label={audience} size="small" />
        ))}
      </Stack>
    </Stack>
    <div className="launch-date">
      Launch date/time: <span>{'01-10-2024'}</span>
    </div>
    <div className="launch-date">
      End date/time: <span>{'31-10-2024'}</span>
    </div>
    <div className="launch-date">
      Total submissions: <span>{'700'}</span>
    </div>
    <div className="launch-date">
      Participation rate: <span>{'70%'}</span>
    </div>
 
  </Stack>
            </Grid>

            <Grid item lg={5} md={5} sm={6} xs={12}>
              <Grid container spacing={1}>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                <WbTextField id="SurveyDropDown" value={selectedSurvey} label="Select Survey" onChange={handleSurveyChange}  defaultValue={selectedSurvey} select>
                    {surveyOptions.map((result) => (
                      <MenuItem key={result.id} value={result.id}>
                        {result.name}
                      </MenuItem>
                    ))}
                  </WbTextField>
                </Grid>
                {customer.customerType !== 2 ? (
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <WbTextField label="Select School" select>
                      <MenuItem value={1}>School One</MenuItem>
                      <MenuItem value={2}>School Two</MenuItem>
                      <MenuItem value={3}>School Three</MenuItem>
                    </WbTextField>
                  </Grid>
                ) : null}
              </Grid>
            </Grid>
          </Grid>

          <Grid container mt={1}>
         
          {/* <Grid item lg={12} md={12} sm={12} xs={12}>
              < HeatmapTable  scoreData={scoreData} generalScore={true} showStaff={false} />
            </Grid> */}
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <SurveyCharts5 />
            </Grid>
          </Grid>
        </Container>
      </div>

      <WbFloatButton surveyInfo={{ surveyId: 90, customerId: customer.id, customerType:customer.customerType }} />
      
    </>
  );
}
