import React from 'react'
//import { useLoader } from './context/LoaderContext';
import {welbeeicon} from '../assets/images/index'
import '../assets/styles/Loader.scss'
import '../assets/styles/chatbot.scss'
import {useLoader} from './WEBLoaderProvider'

const WbLoader = ({children, hideChildren = false, isIcon = true, isLoader=false}) => {
  const {isLoading} = useLoader(true)

  return (
    <>
      {(isLoader || isLoading)  && (
        <div className="loader-overlay">
          <div className="loader-container">
            {isIcon && (
              <div className="loader-icon">
                <div className="loader-border"></div> {/* Spinning border */}
                <figure className="avatar">
                  <img src={welbeeicon} alt="Welbee Icon" />
                </figure>
              </div>
            )}
            <i className="loader-circle"></i>
          </div>
        </div>
      )}
      {!hideChildren && children ? children : <div style={{height: '100vh'}} />}
    </>
  )
}

export default WbLoader
