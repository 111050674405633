import React, {useEffect, useState} from 'react'
import {Container, Grid, Stack, Chip, MenuItem, TextField, Alert} from '@mui/material'
import Header from '../../components/layouts/header/Header'
import WbTextField from '../../components/common/WbTextField'
import WbFloatButton from '../../components/common/WbFloatButton'
import WbButton from '../../components/common/WbButton'
import AddIcon from '@mui/icons-material/Add'
import useCustomerData from '../../hooks/useCustomerData'
import surveysApi from '../../services/api/surveys/surveysApi'
import {useQuery, useMutation} from '@tanstack/react-query'
import useUserData from '../../hooks/useUserData'
import SurveyQuestionsCharts from '../../components/common/SurveyQuestionsCharts'
import WbLoader from '../../components/common/WbLoader'
import { useGenerateLookerUrls } from '../../services/api/looker/hooks/useGenerateLookerUrls'
import { useLoader } from '../../components/common/WEBLoaderProvider'
import { handleApiError } from '../../components/common/handleApiError'
import detectIncognito from '../../utils/detectIncognito'
const getDateString = (date, format) => {
  const months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ]
  const getPaddedComp = comp => (parseInt(comp, 10) < 10 ? `0${comp}` : comp)
  let formattedDate = format
  const o = {
    'y+': date.getFullYear(),
    'M+': months[date.getMonth()],
    'd+': getPaddedComp(date.getDate()),
    'h+': getPaddedComp(
      date.getHours() > 12 ? date.getHours() % 12 : date.getHours()
    ),
    'H+': getPaddedComp(date.getHours()),
    'm+': getPaddedComp(date.getMinutes()),
    's+': getPaddedComp(date.getSeconds()),
    'S+': getPaddedComp(date.getMilliseconds()),
    'b+': date.getHours() >= 12 ? 'PM' : 'AM',
  }

  for (const k in o) {
    if (new RegExp(`(${k})`).test(format)) {
      formattedDate = formattedDate.replace(RegExp.$1, o[k])
    }
  }
  return formattedDate
}

const getAudienceChip = audience => {
  switch (audience) {
    case 1:
      return <Chip className="audience-chip" label="Staff" size="small" />
    case 2:
      return <Chip className="audience-chip" label="Parents" size="small" />
    case 3:
      return (
        <>
          <Chip className="audience-chip" label="Staff" size="small" />
          <Chip className="audience-chip" label="Parent" size="small" />
        </>
      )
    case 4:
      return <Chip className="audience-chip" label="Students" size="small" />
    case 5:
      return (
        <>
          <Chip className="audience-chip" label="Students" size="small" />
          <Chip className="audience-chip" label="Staff" size="small" />
        </>
      )
    case 6:
      return (
        <>
          <Chip className="audience-chip" label="Students" size="small" />
          <Chip className="audience-chip" label="Parents" size="small" />
        </>
      )
    default:
      return (
        <>
          <Chip className="audience-chip" label="Staff" size="small" />
          <Chip className="audience-chip" label="Students" size="small" />
          <Chip className="audience-chip" label="Parents" size="small" />
        </>
      )
  }
}

export default function EDUDashboard2() {
  const customer = useCustomerData()
  const user = useUserData()
  const [selectedSurvey, setSelectedSurvey] = useState('')
  const [schoolId, setSchoolId] = useState('')
  const [schoolSurveyId, setSchoolSurveyId] = useState('')
  const [customerType, setCustomerType] = useState('')
  const [dataset, setDataset] = useState('dev_global_dataset')
  const { showLoader, hideLoader } = useLoader();
  const [isIncognito, setIsIncognito] = useState(false);
  const [browserName, setBrowserName] = useState('');
  

  const {data: surveyOptions, isLoading: surveysLoading, error:AllSurveyError} = useQuery(
    ['surveys', customer.id, customer.customerType],
    () => surveysApi.getAll(customer.id, customer.customerType, 50),
    {
      onSuccess: data => {
        if (data.length > 0) {
          setSelectedSurvey(data[0].id)
          setSchoolSurveyId(data[0].id)
        }
      },
    }
  )

  const {data: survey, isLoading: surveyLoading, error:surveyError} = useQuery(
    ['surveyDetails', selectedSurvey],
    () => (selectedSurvey ? surveysApi.getBySurveyId(selectedSurvey) : null),
    {
      enabled: !!selectedSurvey,
    }
  )

  const handleSurveyChange = e => {
    const selected = e.target.value
    setSelectedSurvey(selected)
    setSchoolSurveyId(selected)
  }
  const {
    mutate: sendMessage,
    data: surveyData,
    error: looksDataError,
   
  } = useGenerateLookerUrls()



  const handlebtnClick = e => {
    sendMessage({
      selectedSurvey: schoolSurveyId,
      customerId: schoolId,
      customerType: customer.customerType,
      datasetName: dataset,
      userId: user.userId,
      firstName: customer.name,
      lastName: '',
      isFirstLogin: false,
    },
    {
      onSuccess: () => {
        showLoader();
        setTimeout(() => {
          hideLoader();
        }, 6000);
      }
    },
  )
  }
  // const errors = [looksDataError, surveyError, AllSurveyError].filter(Boolean);
  // if (errors) {
  //   handleApiError(errors, 'Failed to generate Looker URLs.');
  // }

  useEffect(() => {
    const checkIncognito = async () => {
      try {
        const { isPrivate, browserName } = await detectIncognito();
        if (browserName === 'Chrome' || browserName === 'Safari') {
          setIsIncognito(isPrivate);
        }
        setBrowserName(browserName);
        console.log(`Browser: ${browserName}, Incognito: ${isPrivate}`);
      } catch (error) {
        console.error('Failed to detect incognito mode:', error);
      }
    };
    checkIncognito();
  }, []);
  return (
    <>
      <WbLoader>
        <Header />
        <div className="main-container insights-container">
          <Container maxWidth="lg">
            <Grid container mt={3}>
              <Grid item lg={7} md={7} sm={6} xs={12}>
                <h1>Latest Survey</h1>
                {survey && !surveyLoading ? (
                  <Stack direction="column" mt={4}>
                    <h2>{survey.name}</h2>
                    <Stack direction="row" alignItems="center" my={2}>
                      Audience:
                      <Stack direction="row" spacing={0.5} ml={0.5}>
                        {getAudienceChip(survey.audience)}
                      </Stack>
                    </Stack>
                    <div className="launch-date">
                      Launch date/time:{' '}
                      <span>
                        {getDateString(
                          new Date(survey.createdAt),
                          'M d, y at h:m b'
                        )}
                      </span>
                    </div>
                    <div className="launch-date">
                      End date/time:{' '}
                      <span>
                        {getDateString(
                          new Date(survey.createdAt),
                          'M d, y at h:m b'
                        )}
                      </span>
                    </div>
                  </Stack>
                ) : (
                  <p>Loading survey details...</p>
                )}
              </Grid>

              <Grid item lg={5} md={5} sm={6} xs={12}>
                <Grid container spacing={1}>
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <WbTextField
                      id="SurveyDropDown"
                      label="Select Survey"
                      onChange={handleSurveyChange}
                      select
                      value={selectedSurvey}
                      disabled={surveysLoading}
                    >
                      {surveyOptions?.map(result => (
                        <MenuItem key={result.id} value={result.id}>
                          {result.name}
                        </MenuItem>
                      ))}
                    </WbTextField>
                  </Grid>
                  {customer.customerType !== 2 && (
                    <Grid item lg={6} md={6} sm={6} xs={12}>
                      <WbTextField label="Select School" select>
                        <MenuItem value={1}>School One</MenuItem>
                        <MenuItem value={2}>School Two</MenuItem>
                        <MenuItem value={3}>School Three</MenuItem>
                      </WbTextField>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Grid>

            <Grid container mt={1}>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <div className="body-container">
                  <TextField
                    id="txtSchoolId"
                    label="School/Group Id"
                    variant="outlined"
                    style={{paddingLeft: '5px'}}
                    value={schoolId}
                    onChange={e => setSchoolId(e.target.value)}
                  />
                  <TextField
                    id="txtSchoolSurveyId"
                    label="School/Group Survey Id"
                    variant="outlined"
                    style={{paddingLeft: '5px'}}
                    value={schoolSurveyId}
                    onChange={e => setSchoolSurveyId(e.target.value)}
                  />
                  <TextField
                    id="txtCustomerType"
                    label="school(2) or group(1)"
                    variant="outlined"
                    style={{paddingLeft: '5px'}}
                    value={customerType}
                    onChange={e => setCustomerType(e.target.value)}
                  />
                  <TextField
                    id="txtDataset"
                    label="Dataset"
                    variant="outlined"
                    style={{paddingLeft: '5px'}}
                    value={dataset}
                    onChange={e => setDataset(e.target.value)}
                  />
                       <WbButton
        CustomButtonText={'Generate Analytics'}
        onClick={(e) =>{e.preventDefault(); handlebtnClick()} }
        style={{margin: '10px'}}
      ></WbButton>
                </div>
           
              </Grid>
              {isIncognito? (
        <div className="body-container" style={{padding: '0' , marginTop: '5px'}} >
          <Alert severity="warning" sx={{ width: '100%' }}>
          It looks like you are browsing in Incognito or Private mode or have third party cookies disabled.  Your survey results are currently unable to load in these modes. Please exit Incognito or Private mode to continue.
          </Alert>
          </div>
      
      ):( <SurveyQuestionsCharts
        surveyData={surveyData?.questionUrls}
        isFirstLogin={surveyData?.isFirstLogin}

      />
            
          )}
             
            </Grid>
          </Container>
        </div>
        <WbFloatButton
          surveyInfo={{
            surveyId: schoolSurveyId,
            customerId: schoolId,
            customerType,
          }}
        />
      </WbLoader>
    </>
  )
}
