

import React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import '../../components/assets/styles/chatbot.scss';

export default function HeatmapTable({ scoreData, showStudent = true, showParent = true, showStaff = true, generalScore = false }) {
  return (
    <div className="table-container-wrapper">
      <TableContainer component={Paper} className="styled-table-container" style={{ boxShadow: 'none', borderRadius: '0' }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell className="header-cell">Audience Scores</TableCell>
              {scoreData.map((row, index) => (
                <TableCell key={index} className="header-cell">{row.question}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {showStudent && (
              <TableRow>
                <TableCell className="question-cell">Student</TableCell>
                {scoreData.map((row, index) => (
                  <TableCell key={index} className="data-cell">{row.studentScore !== null ? row.studentScore : '-'}</TableCell>
                ))}
              </TableRow>
            )}
            {showParent && (
              <TableRow>
                <TableCell className="question-cell">Parent</TableCell>
                {scoreData.map((row, index) => (
                  <TableCell key={index} className="data-cell">{row.parentScore !== null ? row.parentScore : '-'}</TableCell>
                ))}
              </TableRow>
            )}
            {showStaff && (
              <TableRow>
                <TableCell className="question-cell">Staff</TableCell>
                {scoreData.map((row, index) => (
                  <TableCell key={index} className="data-cell">{row.staffScore !== null ? row.staffScore : '-'}</TableCell>
                ))}
              </TableRow>
            )}
            {generalScore && (
              <TableRow>
                <TableCell className="question-cell">General</TableCell>
                {scoreData.map((row, index) => (
                  <TableCell key={index} className="data-cell">{row.generalScore !== null ? row.generalScore : '-'}</TableCell>
                ))}
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}


// import React from 'react';
// import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
// import '../../components/assets/styles/chatbot.scss';

// export default function HeatmapTable({ scoreData, showStudent = true, showParent = true, showStaff = true,generalScore = false }) {
//   return (
//     <div className="table-container-wrapper">
//       <TableContainer component={Paper} className="styled-table-container" style={{ boxShadow: 'none', borderRadius: '0' }}>
//         <Table>
//           <TableHead>
//             <TableRow>
//               <TableCell className="header-cell">Questions</TableCell>
//               {showStudent && <TableCell className="header-cell">Student Scores</TableCell>}
//               {showParent && <TableCell className="header-cell">Parent Scores</TableCell>}
//               {showStaff && <TableCell className="header-cell">Staff Scores</TableCell>}
//               {generalScore && <TableCell className="header-cell">General Scores</TableCell>}
//             </TableRow>
//           </TableHead>
//           <TableBody>
//             {scoreData.map((row, index) => (
//               <TableRow key={index}>
//                 <TableCell className="question-cell">{row.question}</TableCell>
//                 {showStudent && <TableCell className="data-cell">{row.studentScore !== null ? row.studentScore : '-'}</TableCell>}
//                 {showParent && <TableCell className="data-cell">{row.parentScore !== null ? row.parentScore : '-'}</TableCell>}
//                 {showStaff && <TableCell className="data-cell">{row.staffScore !== null ? row.staffScore : '-'}</TableCell>}
//                 {generalScore && <TableCell className="data-cell">{row.generalScore !== null ? row.generalScore : '-'}</TableCell>}
//               </TableRow>
//             ))}
//           </TableBody>
//         </Table>
//       </TableContainer>
//     </div>
//   );
// }
