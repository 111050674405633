import {CircularProgress,} from '@mui/material';
import React, {useState, useEffect} from 'react';
import axios from 'axios';
import {CUSTOMER_TYPE, Roles} from '../../utils/constants';
import {json, useNavigate} from 'react-router-dom';
import WbAlert from '../../components/common/WbAlert';
import welbeelogo from '../../components/assets/images/welbee_logo.svg';
import {parseJwt} from '../../utils/utilities';
import { useSelector } from 'react-redux';
import { signinRedirect } from '../../services/api/user/userApi';
import {Config} from '../../utils/Config';

export default function Login() {
  let [lockScreen, setLockScreen] = useState(false)
  const navigate = useNavigate();
  const user = useSelector(state => state.auth.user);

  useEffect(() => {
    debugger;
      if (!user) {
          signinRedirect();
      }

      if (user != null) {
        onLogin(user)
      }
  }, []);

  const onLogin = (user) => {
    axios({
      method: 'post',
      url: Config.ADMIN_BASE_URL + '/account/login',
      headers: {'Content-Type': 'application/json'}, 
      data: JSON.stringify(user)
    }).then(res => {
      if (res.data.success === false) {
        setLockScreen(false)
        WbAlert({message: res.data.message, type: 'error'})
        return
      }
      const userInfo = res.data.userInfo;
        let customerType =
          userInfo.Role === Roles.GROUP_DIRECTOR
            ? CUSTOMER_TYPE.MAT
            : CUSTOMER_TYPE.School

        let customer = {
          id:
            userInfo.Role === Roles.GROUP_DIRECTOR
              ? res.data.MatGroupId
              : res.data.schoolId,
          name: res.data.schoolName,
          customerType: customerType,
      }

      let user = {
        name:
          'Insights' +
          ' ' +
          'User',
        isMat:
          res.data.MatGroupId == 0 || res.data.MatGroupId === null
            ? false
            : true,
        email: res.data.userInfo.Email,
      }

      debugger

      localStorage.setItem('token', res.data.access_token)
      localStorage.setItem('customer', JSON.stringify(customer))
      localStorage.setItem('user', JSON.stringify(user))

      setLockScreen(false)

      if (localStorage.getItem('RedirectUrl') != null){
        navigate(localStorage.getItem('RedirectUrl'))  
      } else {
        navigate('/EDUDashboard')
      }
    })
    .catch(err => {
      console.log(err)
      setLockScreen(false)
    })
  }

  return (
    <>
      <div className="loading">
        <div className="login-logo">
          <img src={welbeelogo} alt="" />
        </div>
        <div>
          <svg width={0} height={0}>
            <defs>
              <linearGradient id="my_gradient" x1="0%" y1="0%" x2="0%" y2="100%">
                <stop offset="0%" stopColor="#e01cd5" />
                <stop offset="100%" stopColor="#1CB5E0" />
              </linearGradient>
            </defs>
          </svg>
          <CircularProgress sx={{ 'svg circle': { stroke: 'url(#my_gradient)' } }} size={50} />
        </div>        
      </div>
    </>
  )
}
