import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import App from './App'
import {createTheme, ThemeProvider} from '@mui/material/styles';
import { HeadProvider, Title, Link, Meta } from 'react-head';
import ReactQueryProvider from './components/common/ReactQueryProvider';
import { LoaderProvider } from './components/common/WEBLoaderProvider';

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <HeadProvider>
    <meta charset="UTF-8" />
    <script src="https://cdn.jsdelivr.net/npm/@supabase/supabase-js"></script>
    <LoaderProvider>
      <ReactQueryProvider>
        <App />
      </ReactQueryProvider>
    </LoaderProvider>
  </HeadProvider>
)
