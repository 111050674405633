import React from 'react'
import {QueryClient, QueryClientProvider} from '@tanstack/react-query'
import {useLoader} from './WEBLoaderProvider'

export const ReactQueryProvider = ({children}) => {
  const {showLoader, hideLoader} = useLoader()
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        retry: 1,
        cacheTime: 0,
        refetchOnWindowFocus: false,
        onSettled: () => hideLoader(),
        onError: () => hideLoader(),
      },
      mutations: {
        onMutate: () => showLoader(),
        onSettled: () => hideLoader(),
        onError: () => hideLoader(),
      },
    },
  })

  return (
    <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
  )
}
export default ReactQueryProvider
