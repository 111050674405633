import { useMutation } from '@tanstack/react-query';
import surveysApi from '../surveysApi';
import { toast } from 'react-toastify';
import { useLoader } from '../../../../components/common/WEBLoaderProvider';
import { handleApiError } from '../../../../components/common/handleApiError';

export const useMutateGetAllSurveys = () => {
    const { hideLoader} = useLoader();
  return useMutation(
    ({ customerId, customerType },) => surveysApi.getAll(customerId, customerType),
    
    {
      onError: (error) => {
        handleApiError(error, 'Failed to load all surveys.');
        hideLoader();
          const errorResponse = Object(error)?.error || Object(error) || 'Unknown error occurred';      
          toast.error(`Failed to load all surveys: ${errorResponse}`, {
            autoClose: 8000,
            position: 'top-center', 
          });
      },
   
      retry:false

    }
  );
};
