import React, { useState, useEffect } from "react";
import axios from "axios";
import { LookerEmbedSDK } from "@looker/embed-sdk";
import { Grid } from "@mui/material";
import QuestionCard from "./QuestionCard";

export default function SurveyQuestionsCharts({ surveyData }) {
  const [isAuthenticated, setIsAuthenticated] = useState(false); // Track if Looker session is authenticated
  const [isZoomed, setIsZoomed] = useState(false);

  useEffect(() => {
    LookerEmbedSDK.init("https://welbee.cloud.looker.com");
    const firstUrl = surveyData?.lookerSignedUrl;
    const iframe = document.createElement("iframe");
    iframe.style.display = "none";
    iframe.src = firstUrl;
    iframe.onload = () => {
      console.log("Looker authenticated");
      setIsAuthenticated(true);
      document.body.removeChild(iframe); 
    };
    iframe.onerror = () => {
      console.error("Error authenticating with Looker");
    };
    document.body.appendChild(iframe);
  }, [surveyData]);
  


  return (
    <Grid container spacing={2} mt={1} mb={2}>
      {isAuthenticated &&
        surveyData?.map((item, index) => (
            <Grid
              key={index}
              item
              lg={isZoomed ? 12 : 6}
              md={isZoomed ? 12 : 6}
              sm={12}
              xs={12}
            >
              <QuestionCard
                item={item}
                index={index}
                onZoom={() => setIsZoomed(!isZoomed)}
              />
            </Grid>
          ))}
    </Grid>
  );
}



// export default function SurveyQuestionsCharts({ surveyData }) {
//   const [currentIndex, setCurrentIndex] = useState(1); // Start with the first two questions loaded

 
//   // useEffect(() => {
//   //   setCurrentIndex(0); 
//   // }, [surveyData]);

 
//   // useEffect(() => {
//   //   if (currentIndex < surveyData?.length - 1) {
//   //     const timeout = setTimeout(() => {
//   //       console.log('Sequencial loading looks:', currentIndex + 1);
//   //       setCurrentIndex((prevIndex) => prevIndex + 1);
//   //     }, currentIndex === 0 ? 0 : 1000); 

//   //     return () => clearTimeout(timeout);
//   //   }
//   // }, [currentIndex, surveyData?.length]);



//   useEffect(() => {
//     setCurrentIndex(1); // Reset to load the first two questions on surveyData change
//   }, [surveyData]);
 
//   useEffect(() => {
//     if (currentIndex < surveyData?.length - 1) {
//       const timeout = setTimeout(() => {
//         const remainingItems = surveyData.length - currentIndex - 1;
//         const nextBatch = remainingItems > 1 ? 2 : 1; // Load 2 questions if possible, otherwise 1
//         console.log(
//           `Loading ${nextBatch} more questions, starting at index ${currentIndex + 1}`
//         );
//         setCurrentIndex((prevIndex) => prevIndex + nextBatch);
//       }, 3000); // Add 3-second delay for subsequent questions

//       return () => clearTimeout(timeout);
//     }
//   }, [currentIndex, surveyData]);

//   return (
//     <Grid container spacing={2} mt={1} mb={2}>
//       {surveyData
//         ?.slice(0, currentIndex + 1)
//         .map((item, index) => (          
//           <Grid item lg={6} md={6} sm={12} xs={12}>
//             <QuestionCard
//               key={index}
//               item={item}
//               count={surveyData?.length}
//               index={index}
//               isLoaded={index <= currentIndex}
//             />
//           </Grid>
//         ))}
//     </Grid>
//   );
// }

