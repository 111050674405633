

import React, { useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExpandArrowsAlt } from '@fortawesome/free-solid-svg-icons';
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend,ChartDataLabels);
export default function SurveyCharts5() {
  

    const surveyData5 = [
        {
          questionHeader: 'Q1',
          questionText: (
            <p style={{ textAlign: 'center' }}>
              <strong>Students:</strong> Which of these devices do you use to go online?<br />
              <strong>Parents:</strong> Which of these devices does your child use to go online? (Tick all that apply)
            </p>
          ),
          labels: [
            'Smartphone (e.g., iPhone, Android)', 
            'Tablet (e.g., iPad)', 
            'Laptop', 
            'Desktop computer', 
            'Smart TV', 
            'Games console (e.g., PlayStation, Xbox, Switch)', 
            'Virtual Reality (VR) headset', 
            'Other (please specify)'
          ],
          datasets: [
            {
              label: 'Student',
              data: [88, 62, 78, 52, 38, 27, 11, 4],
              backgroundColor: '#4db8ff',
            },
            {
              label: 'Parent',
              data: [82, 58, 72, 48, 32, 23, 9, 6],
              backgroundColor: '#ffcc33',
            }
          ]
        },
        {
          questionHeader: 'Q2',
          questionText: (
            <p style={{ textAlign: 'center' }}>
              <strong>Students:</strong> How much time do you spend online on a typical weekend day?<br />
              <strong>Parents:</strong> How much time do you think your child spends online on a typical weekend day?
            </p>
          ),
          labels: [
            'Less than 1 hour', 
            '1-2 hours', 
            '2-3 hours', 
            '3-4 hours', 
            '4-5 hours', 
            'More than 5 hours'
          ],
          datasets: [
            {
              label: 'Student',
              data: [12, 28, 32, 18, 7, 3],
              backgroundColor: '#4db8ff',
            },
            {
              label: 'Parent',
              data: [8, 22, 28, 22, 10, 10],
              backgroundColor: '#ffcc33',
            }
          ]
        },
        {
          questionHeader: 'Q3',
          questionText: (
            <p style={{ textAlign: 'center' }}>
              <strong>Students:</strong> What do you mainly do online? (Tick your top 3)<br />
              <strong>Parents:</strong> What do you think your child mainly does online? (Tick their top 3)
            </p>
          ),
          labels: [
            'Social media (e.g., Snapchat, Instagram, TikTok)',
            'Online gaming',
            'Watching videos (e.g., YouTube, Netflix)',
            'Streaming music',
            'Online shopping',
            'Doing homework or research',
            'Communicating with friends and family (e.g., messaging, video calls)',
            'Reading news or blogs',
            'Other (please specify)'
          ],
          datasets: [
            {
              label: 'Student',
              data: [78, 52, 72, 63, 22, 68, 62, 18, 4],
              backgroundColor: '#4db8ff',
            },
            {
              label: 'Parent',
              data: [72, 48, 68, 58, 28, 62, 58, 22, 6],
              backgroundColor: '#ffcc33',
            }
          ]
        },
        {
          questionHeader: 'Q4',
          questionText: (
            <p style={{ textAlign: 'center' }}>
              <strong>Students:</strong> Have any of the following things ever made you feel uncomfortable, worried, or upset? (Tick all that apply)<br />
              <strong>Parents:</strong> Have you discussed any of the following things with your child, that may have made them feel uncomfortable, worried, or upset online? (Tick all that apply)
            </p>
          ),
          labels: [
            'Someone being mean or bullying others',
            'Inappropriate or upsetting content (e.g., violence, pornography)',
            'Feeling pressured to share personal information',
            'Unwanted contact from strangers',
            'None of the above',
            'Something else (please specify)'
          ],
          datasets: [
            {
              label: 'Student',
              data: [52, 42, 38, 28, 9, 5],
              backgroundColor: '#4db8ff',
            },
            {
              label: 'Parent',
              data: [48, 38, 32, 27, 11, 6],
              backgroundColor: '#ffcc33',
            }
          ]
        },
        {
          questionHeader: 'Q5',
          questionText: (
            <p style={{ textAlign: 'center' }}>
              <strong>Students:</strong> Do you know what to do if you experience or witness online bullying?<br />
              <strong>Parents:</strong> Do you think your child knows what to do if they experience or witness online bullying?
            </p>
          ),
          labels: ['Yes', 'No'],
          datasets: [
            {
              label: 'Student',
              data: [88, 12],
              backgroundColor: '#4db8ff',
            },
            {
              label: 'Parent',
              data: [80, 20],
              backgroundColor: '#ffcc33',
            }
          ]
        },
        {
          questionHeader: 'Q6',
          questionText: (
            <p style={{ textAlign: 'center' }}>
              <strong>Students:</strong> Do you think your school provides enough information about staying safe online?<br />
              <strong>Parents:</strong> Do you think the school provides enough information to your child about staying safe online?
            </p>
          ),
          labels: ['Strongly Agree', 'Agree', 'Neutral', 'Disagree', 'Strongly Disagree'],
          datasets: [
            {
              label: 'Student',
              data: [48, 25, 15, 8, 4],
              backgroundColor: '#4db8ff',
            },
            {
              label: 'Parent',
              data: [42, 28, 18, 8, 4],
              backgroundColor: '#ffcc33',
            }
          ]
        },
        {
          questionHeader: 'Q7',
          questionText: (
            <p style={{ textAlign: 'center' }}>
              <strong>Students:</strong> How confident are you in identifying fake news or misinformation online?<br />
              <strong>Parents:</strong> How confident do you think your child is in identifying fake news or misinformation online?
            </p>
          ),
          labels: ['Very Confident', 'Confident', 'Neutral', 'Not Very Confident', 'Not at All Confident'],
          datasets: [
            {
              label: 'Student',
              data: [15, 30, 25, 20, 10],
              backgroundColor: '#4db8ff',
            },
            {
              label: 'Parent',
              data: [12, 28, 28, 22, 10],
              backgroundColor: '#ffcc33',
            }
          ]
        },
        {
          questionHeader: 'Q8',
          questionText: (
            <p style={{ textAlign: 'center' }}>
              <strong>Students:</strong> How often do you discuss online safety with your family?<br />
              <strong>Parents:</strong> How often do you discuss online safety with your child?
            </p>
          ),
          labels: ['Daily', 'Weekly', 'Monthly', 'Rarely', 'Never'],
          datasets: [
            {
              label: 'Student',
              data: [18, 32, 24, 16, 10],
              backgroundColor: '#4db8ff',
            },
            {
              label: 'Parent',
              data: [22, 34, 20, 14, 10],
              backgroundColor: '#ffcc33',
            }
          ]
        },
        {
          questionHeader: 'Q9',
          questionText: (
            <p style={{ textAlign: 'center' }}>
              <strong>Students:</strong> How do you feel about sharing personal information online?<br />
              <strong>Parents:</strong> How do you think your child feels about sharing personal information online?
            </p>
          ),
          labels: ['Very Comfortable', 'Comfortable', 'Neutral', 'Uncomfortable', 'Very Uncomfortable'],
          datasets: [
            {
              label: 'Student',
              data: [10, 15, 20, 25, 30],
              backgroundColor: '#4db8ff',
            },
            {
              label: 'Parent',
              data: [8, 14, 22, 28, 28],
              backgroundColor: '#ffcc33',
            }
          ]
        },
        {
          questionHeader: 'Q10',
          questionText: (
            <p style={{ textAlign: 'center' }}>
              <strong>Students:</strong> Have you ever reported an issue you experienced online to an adult?<br />
              <strong>Parents:</strong> Do you think your child would report an issue they experienced online to an adult?
            </p>
          ),
          labels: ['Yes', 'No'],
          datasets: [
            {
              label: 'Student',
              data: [62, 38],
              backgroundColor: '#4db8ff',
            },
            {
              label: 'Parent',
              data: [58, 42],
              backgroundColor: '#ffcc33',
            }
          ]
        }
      ];
      
  
  const [isZoomed, setIsZoomed] = useState(false);

  
  const options = {
    responsive: true,
    maintainAspectRatio: !isZoomed,
    aspectRatio: 1.2, 
    plugins: {
      legend: {
        position: 'bottom',
        labels: {
          usePointStyle: true,
        },
      },
      datalabels: {
        anchor: 'end',
        align: 'top',
        formatter: (value) => `${value}%`,
        color: 'black',
        font: {
          weight: 'bold',
        },
      },
      tooltip: {
        callbacks: {
          label: (tooltipItem) => `${tooltipItem.raw}%`,
        },
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        max: 100,
        ticks: {
          callback: (value) => `${value}%`,
        },
      },
      x: {
        ticks: {
          maxRotation: 45,
          minRotation: 0,
        },
      },
    },
  };

  const toggleZoom = () => {
    setIsZoomed(!isZoomed);
  };

  return (
    <div style={{ display: 'flex', flexWrap: 'wrap', gap: '10px', justifyContent: 'space-between' }}>
      {surveyData5.map((item, index) => (
        <div
          key={index}
          style={{
            width: isZoomed ? '100%' : '49.5%',
            backgroundColor: '#fff',
            borderRadius: '0.0rem',
            boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
            padding: '10px',
            marginBottom: '10px',
            position: 'relative',
            transition: 'width 0.3s', // Smooth transition for width change
          }}
        >
          <h3 style={{ textAlign: 'center' }}>{item.questionHeader}</h3>
          {item.questionText}
          <div style={{ paddingTop: '14px' }}>
            <Bar
              data={{
                labels: item.labels,
                datasets: item.datasets,
              }}
              key={index}
              options={options}
            />
          </div>
          <button
            onClick={toggleZoom}
            style={{
              position: 'absolute',
              top: '10px',
              right: '10px',
              width: '30px',
              height: '30px',
              borderRadius: '50%',
              border: 'none',
              cursor: 'pointer',
              backgroundColor: '#007A7A',
              color: '#fff',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              boxShadow: '0 2px 5px rgba(0, 0, 0, 0.15)',
              fontSize: '18px',
            }}
          >
            <FontAwesomeIcon icon={faExpandArrowsAlt} color={'#fff'} />
          </button>
        </div>
      ))}
    </div>
  );
}