import {useMutation} from '@tanstack/react-query'
import {toast} from 'react-toastify'
import lookerApi from '../lookerAPI'
import { useLoader } from '../../../../components/common/WEBLoaderProvider'
import { handleApiError } from '../../../../components/common/handleApiError'


export const useGenerateLookerUrls = () => {
  const { hideLoader,showLoader} = useLoader()
  const preconnectToLooker = () => {
    const domain = "https://welbee.cloud.looker.com";
    const head = document.head;
  
    if (!document.querySelector(`link[rel="preconnect"][href="${domain}"]`)) {
      const link = document.createElement("link");
      link.rel = "preconnect";
      link.href = domain;
      head.appendChild(link);
      console.log(`Preconnected to: ${domain}`);
    }
  };

  const preloadLookerUrls = (urls) => {
    const head = document.head;

    document.querySelectorAll('link[rel="preload"]').forEach((link) => {
      if (urls.includes(link.href)) {
        head.removeChild(link);
      }
    });
    urls.forEach((url) => {
      if (!document.querySelector(`link[rel="preload"][href="${url}"]`)) {
        const link = document.createElement("link");
        link.rel = "preload";
        link.as = "document"; 
        link.href = url;
        head.appendChild(link);
        console.log(`Preloading URL: ${url}`);
      }
    });
  };
  
  return useMutation(
    ({
      customerId,
      selectedSurvey,
      customerType,
      datasetName,
      userId,
      firstName,
      lastName,
      isFirstLogin
    }) =>
      lookerApi.GenerateSignedLookerUrlsQuery(
        customerId,
        selectedSurvey,
        customerType,
        datasetName,
        userId,
        firstName,
        lastName,
        isFirstLogin
      ),
    
      {
        onSuccess: (data) => {
          preconnectToLooker();
          if (Array.isArray(data) && data.length > 0) {
            const urls = data.map((item) => item.url).filter(Boolean);         
            try {
              preloadLookerUrls(urls); 
            } catch (error) {
              console.error('Error during preloading:', error);
            }
          } else {
            console.warn('No URLs to preload.');
          }
          showLoader();
          setTimeout(() => {
            hideLoader();
          }, 3000);
        }
      },
      
      {
        onError: (error) => {
          throw error;
       
         
          // const errorResponse = Object(error)?.error || Object(error) || 'Unknown error occurred';      
          // toast.error(`Failed to generate Looker URLs: ${errorResponse}`, {
          //   autoClose: 8000,
          //   position: 'top-center', 
          // });
        },
      }
  )
}
