import { useMutation } from '@tanstack/react-query';
import surveysApi from '../surveysApi';
import { toast } from 'react-toastify';
import { useLoader } from '../../../../components/common/WEBLoaderProvider';
import { handleApiError } from '../../../../components/common/handleApiError';

export const useMutateGetSurveyDetails = () => {
    const { hideLoader} = useLoader()
  return useMutation(
    ({ surveyId, cType }) => surveysApi.GetSurveyDashboard(surveyId, cType),
    {
      onError: (error) => {
        hideLoader();
        handleApiError(error, 'Failed to load selected survey.');
     
        //   const errorResponse = Object(error)?.error || Object(error) || 'Unknown error occurred';      
        //   toast.error(`Selected survey: ${errorResponse}`, {
        //     autoClose: 8000,
        //     position: 'top-center', 
        //   });
      },
  
      retry:false
    }
  );
};
