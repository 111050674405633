import { toast } from 'react-toastify';


export const handleApiError = (error, defaultMessage = 'An unexpected error occurred.') => {
 console.log('error',defaultMessage)
    const prefix = defaultMessage === 'EDU AI API' ? '(EDU AI) ' : '';
    const errorMessage =
      prefix +
      (
        error?.response?.data?.title || // Standard error title
        error?.response?.data?.detail || // Detailed message
        error?.message || // Generic error message
        error?.title || // Fallback to title
        defaultMessage // Default message
      );
    toast.error(errorMessage, {
      autoClose: 8000,
      position: 'top-center',
    });
  };
  