
import request from '../../request'
import {eduIntellDeleteHistory} from '../../endpoints'
import { handleApiError } from '../../../components/common/handleApiError';
export default class eduIntellDeleteHistoryAPI {  
    static async eDUIntellDeleteHistory(surveyId,customerId,CustomerType,userId) {
      try {
        return await request({
          url: eduIntellDeleteHistory.EDUIntellDeleteHistory(surveyId,customerId,CustomerType,userId),
          method: 'Delete',
        })
      } catch (error) {
        handleApiError(error, 'Failed to get Looker data.');
        throw error;
      }
     
    }
  }