import React, { useState, useEffect, useRef } from 'react';
import { Dialog, Fab } from '@mui/material';
import ReviewsSharpIcon from '@mui/icons-material/ReviewsSharp';
// import useCustomerData from '../../hooks/useCustomerData';
import useUserData from '../../hooks/useUserData';
import EDUIntellApi from '../../services/api/eduIntell/eduIntellAPI';
import { welbeeicon } from '../assets/images/index';
import '../assets/styles/chatbot.scss';
import { useMutation } from '@tanstack/react-query';
import eduIntellDeleteHistoryAPI from '../../services/api/eduIntell/eduIntellDeleteHistoryAPI';
import $ from 'jquery';
import { useLoader } from './WEBLoaderProvider';
import { toast } from 'react-toastify';
import { handleApiError } from './handleApiError';

const fabStyle = {
  position: 'fixed',
  bottom: 16,
  right: 16,
  bgcolor: '#007A7A',
  '&:hover': {
    bgcolor: '#45338C',
  },
};

function SimpleDialog({ onClose, surveyInfo, open }) {
  //const customerData = useCustomerData();
  const {  hideLoader } = useLoader();
  const userData = useUserData();
  const [messages, setMessages] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const messagesEndRef = useRef(null);
  const { mutate: sendMessage, isLoading, error:EDUAPiError } = useMutation(
    ({ selectedSurvey, customerId, customerType, userId, msg, callType }) =>
      EDUIntellApi.GetEDUIntell(selectedSurvey, customerId, customerType, 'dev_global_dataset', userId, msg, callType),
    {
      retry: false,
      onMutate: () => hideLoader(), // hide global loader on mutation start
      onSettled: () => hideLoader(), // Hide global loader after mutation completes
      onSuccess: (data) => {       
        setMessages((prevMessages) => [
          ...prevMessages,
          {
            type: 'bot',
            text: data.response || 'No response received',
            timestamp: new Date().toLocaleTimeString(),
          },
        ]);
        scrollToBottom();
      },
      onError: (error) => {
        setMessages((prevMessages) => [
          ...prevMessages,
          { type: 'bot', text: 'Failed to fetch response. Please try again later.' },
        ]);
        scrollToBottom();
     
        throw error;
      },
      
    }
  );

  const { mutate: deleteHistory, isLoading: isDeleting } = useMutation(
    ({ selectedSurvey, customerId, userId, customerType }) =>
      eduIntellDeleteHistoryAPI.eDUIntellDeleteHistory(selectedSurvey, customerId,customerType, userId), 
    {
      retry: false,
      onMutate: () => hideLoader(), // hide global loader on mutation start
      onSettled: () => hideLoader(), // Hide global loader after mutation completes
      onSuccess: () => {
        setMessages([]);
        sendMessage({
          selectedSurvey: surveyInfo.surveyId,
          customerId: surveyInfo.customerId,
          customerType: surveyInfo.customerType,
          userId: userData.Id,
          msg: '',
          callType: 'Primary',
        });
      },
      onError: (error) => {
        hideLoader()
        throw error;
      },
    }
  );
  

$.fn.scrollDivToElement = function(elem, speed) {
  var $this = $(this);
  if( $this.offset()!==undefined)
  {
    var $this_top = $this.offset().top;
    var $this_bottom = $this_top + $this.height();
    var $elem = $(elem);
    var $elem_top = $elem.offset().top;
    var $elem_bottom = $elem_top + $elem.height();

    if ($elem_top > $this_top && $elem_bottom < $this_bottom) {
      
        return;
    }
    var new_scroll_top;
    if ($elem_top < $this_top) {
        new_scroll_top = {scrollTop: ($this.scrollTop() - $this_top + $elem_top)+200};
    } else {
      
        new_scroll_top = {scrollTop: ($elem_top - $this_top - 50 + $this.scrollTop())};  
    }
    $this.animate(new_scroll_top, speed === undefined ? 100 : speed);    
    return this;
  }
  
};

  const handleDeleteHistory = () => {
    try {
      deleteHistory({
        selectedSurvey: surveyInfo.surveyId,
        customerId: surveyInfo.customerId,
        customerType: surveyInfo.customerType,
        userId: userData.Id,
       
      });
      setMessages([]);  
      
    } catch (error) {
      
      handleApiError(error, 'Failed to fetch response. Please try again later.');
      
    }
    
  };

  useEffect(() => {
    if (open) {
      try {
        setMessages([]);
        sendMessage({
          selectedSurvey: surveyInfo.surveyId,
          customerId: surveyInfo.customerId,
          customerType: surveyInfo.customerType,
          userId: userData.Id,
          msg: '',
          callType: 'Primary',
        });
      } catch (error) {

        handleApiError(error, 'Failed to fetch response. Please try again later.');
      }
     
    }
    
  }, [open, surveyInfo, userData.Id, sendMessage]);

  const handleSendMessage = () => {
    try {
      if (inputValue.trim() === '') return;
      setMessages((prevMessages) => [
        ...prevMessages,
        { type: 'user', text: inputValue, timestamp: new Date().toLocaleTimeString() },
      ]);
      sendMessage({
        selectedSurvey: surveyInfo.surveyId,
        customerId: surveyInfo.customerId,
        customerType: surveyInfo.customerType,
        userId: userData.Id,
        msg: inputValue,
        callType: 'Secondary',
      });
      setInputValue('');
    } catch (error) {
   
      handleApiError(error, 'Failed to fetch response. Please try again later.');
      
    }
       
  };

  const scrollToBottom = () => {   
    setTimeout(function() {$('.messages-content').scrollDivToElement('.new:last',1000)}, 1000);      
   
  };

  return (
    <Dialog onClose={onClose} open={open} className="chat-box" maxWidth="md" fullWidth>
      <div className="chat">
        <div className="chat-title">
          <h1>Intelligent Ed</h1>
          <h2>Welbee AI</h2>
          <figure class="avatar">
            <img src={welbeeicon} alt="Welbee" />
          </figure>           
      <button
        type="submit"
        className="message-submit-clh"
        onClick={handleDeleteHistory}
        disabled={isLoading}
      >
                {isDeleting ? (
      'Deleting...'
    ) : (
      'Clear History'
    )}  
      </button>     
        </div>
        <div className="messages" >
          <div className="messages-content" ref={messagesEndRef}>
          {messages.map((message, index) => (
              message.text.includes("class='avatar'") ? (
                <div dangerouslySetInnerHTML={{ __html: message.text }} />
              ) : (
                <div key={index} className={`message ${message.type === 'user' ? 'message-personal' : 'new'}`}>
                  {message.type === 'bot' && <figure class="avatar"><img src={welbeeicon} alt="Welbee" /></figure>}
                  <div dangerouslySetInnerHTML={{ __html: message.text }} />
                  <div className="timestamp">{message.timestamp}</div>
                </div> 
              )                        
          ))}
          <div ref={messagesEndRef}></div>
          </div>
        </div>    
        
        {isLoading && (
          <div className="messages" style={{maxHeight:'50px'}}> <div className="message loading new" ><figure class="avatar"><img  src={welbeeicon} alt="Loading" /></figure><span></span></div></div>                
        )}        
        <div className="message-box">
          <textarea
            type="text"
            className="message-input"
            placeholder="Type message..."
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === 'Enter' && !e.shiftKey) {
                e.preventDefault();
                handleSendMessage();
              }
            }}
          ></textarea>
          <button type="submit" className="message-submit" onClick={handleSendMessage} disabled={isLoading}>
            Send
          </button>
        </div>
      </div>
    </Dialog>
  );
}

export default function WbFloatButton({ surveyInfo }) {
  const [open, setOpen] = useState(true);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Fab aria-label="chatbot" onClick={handleClickOpen} sx={fabStyle}>
        <ReviewsSharpIcon sx={{ color: '#fff' }} />
      </Fab>
      <SimpleDialog open={open} onClose={handleClose} surveyInfo={surveyInfo} />
    </div>
  );
}