import request from '../../request'
import {looker} from '../../endpoints'
import { handleApiError } from '../../../components/common/handleApiError';

export default class lookerAPI {  
  static async GenerateSignedLookerUrlsQuery(schoolId,surveyId,CustomerType,fetchedDataset,userId, firstName,lastName,isFirstLogin) {
    try {
      return await request({
        url: looker.GenerateSignedLookerUrlsQuery(schoolId,surveyId,CustomerType,fetchedDataset,userId, firstName,lastName,isFirstLogin),
        method: 'GET',
      })
    } catch (error) {
      handleApiError(error, 'Failed to get Looker data.');
      throw error;
    }
   
  }
}
